import { Component, OnInit } from '@angular/core';
import {
  Firestore,
  collection,
  query,
  where,
  limit,
  orderBy,
  collectionData,
  doc,
  getDoc,
  onSnapshot,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform, NavController, PopoverController, AlertController } from '@ionic/angular';
import * as _ from 'lodash';
import moment from 'moment';

import { environment } from 'src/environments/environment';
import { Profile } from 'src/models';
import { AnalyticsService } from 'src/services/analytics.service';
import { AuthService } from 'src/services/auth.service';
import { CommandbarService } from 'src/services/commandbar.service';
import { GlobalCreateService } from 'src/services/global-create.service';
import { MessageService } from 'src/services/message.service';

import { ActionDropdownComponent, ActionDropdownType } from './components/action-dropdown/action-dropdown.component';
import { collections, routes } from './constants';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfilePopoverPage } from './profile/profile-popover/profile-popover.page';
import { hexToRgb } from './utils/utils';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  profile: Profile;
  buttonsDisabled = false;
  isReady = false;
  notifications: any[] = [];
  unreadNotifications = 0;
  hideNavBar = false;
  trialStatus: string;
  trialExpired: boolean;
  isSuperAdmin = false;
  gitHash: string;

  public environment = environment;
  public appPages = [];

  constructor(
    private platform: Platform,
    private router: Router,
    private route: ActivatedRoute,
    private afs: Firestore,
    private navController: NavController,
    private popoverCtrl: PopoverController,
    private msgSrvc: MessageService,
    private alertCtrl: AlertController,
    public authService: AuthService,
    private analyticsService: AnalyticsService,
    private globalCreateService: GlobalCreateService,
    private commandbarService: CommandbarService,
  ) {
    this.appPages = [
      {
        title: 'Home',
        url: `/${routes.DASHBOARD}/${routes.HOME}`,
        icon: 'home',
      },
      {
        title: 'Inbox',
        url: `/${routes.DASHBOARD}/${routes.INBOX}`,
        icon: 'file-tray',
      },
    ];

    this.initializeApp();

    this.authService.onAuthReady(() => {
      this.configureForTenant();
      this.subscribeToNotifications();
      this.commandbarService.init();
    });
  }

  initializeApp() {
    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.setAttribute('data-theme', 'dark');
    }
    this.platform.ready().then(() => {});
  }

  async ngOnInit() {
    try {
      const appConfigReference = doc(this.afs, collections.APP_CONFIG, 'frontend');
      const appConfig = await getDoc(appConfigReference);

      if (appConfig.exists()) {
        const data = appConfig.data();

        this.gitHash = data.hash;
      }

      onSnapshot(appConfigReference, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          const gitHash = data.hash;

          if (this.gitHash !== gitHash) {
            // window.location.reload();
            this.showSuggestReloadPrompt();
          }

          this.gitHash = data.hash;
        }
      });
    } catch (err) {
      console.error('Error getting app config: ', err);
    }

    // Start analytics
    this.analyticsService.init(environment.googleAnalyticsTrackingId);
    this.route.queryParams.subscribe((params) => {
      // Janky way of seeing if we're redirecting for oauth in the app.unthread.io domain
      this.hideNavBar = !!params.redirectForOAuth && !this.authService.tenantId;
    });
  }

  async configureForTenant() {
    const primaryColor = this.authService.primaryColor;
    const tenant = this.authService.tenant;

    if (primaryColor) {
      document.body.style.setProperty(`--ion-color-primary`, primaryColor);
      document.body.style.setProperty(`--ion-color-primary-rgb`, hexToRgb(primaryColor));
    }

    if (tenant?.accountStatus === 'trial') {
      if (tenant.trialEndDate) {
        const end = moment(tenant.trialEndDate);
        this.trialExpired = end.diff(moment()) < 0;
        this.trialStatus = this.trialExpired
          ? `Trial expired. Click to upgrade`
          : `Trial expires ${moment(tenant.trialEndDate).fromNow()}`;
      } else {
        this.trialStatus = 'Trial period';
        this.trialExpired = false;
      }
    }

    this.isSuperAdmin = this.authService.isSuperAdmin && _.get(tenant, 'isInternal');
    this.isReady = true;
  }

  async subscribeToNotifications() {
    if (!this.authService.user) {
      return;
    }

    collectionData(
      query(
        collection(this.afs, collections.NOTIFICATIONS),
        where('userId', '==', this.authService.userId),
        where('tenantId', '==', this.authService.tenantId),
        limit(25),
        orderBy('createdAt', 'desc'),
      ),
      { idField: 'objectId' },
    ).subscribe(
      (val) => {
        this.notifications = val;

        let notifs = 0;
        this.notifications.forEach((n) => {
          if (!n.read) {
            notifs++;
          }
        });
        this.unreadNotifications = notifs;
      },
      (err) => {
        console.log('Error getting notifications: ', err);
      },
    );
  }

  async openProfile() {
    const popover = await this.popoverCtrl.create({
      component: ProfilePopoverPage,
      componentProps: {},
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async openCommandBar() {
    this.analyticsService.trackEvent('Nav', 'Open Command Bar');

    window.CommandBar?.open();
  }

  async openGiftOptions() {
    const options: ActionDropdownType[] = [
      {
        label: 'Product Updates',
        icon: 'rocket-outline',
      },
      {
        label: 'Refer a Friend',
        icon: 'heart-outline',
      },
    ];

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (idx: number) => {
          popover.dismiss();

          if (idx === 0) {
            window.open('https://unthread.io/blog/tag/product-updates/', '_blank');
          } else if (idx === 1) {
            this.openReferral();
          }
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async openReferral() {
    this.analyticsService.trackEvent('Nav', 'View Referral');

    const segments = this.authService.tenantId.split('-');
    const code = segments[segments.length - 1];
    const link = `unthread.io/?referral=${code}`;

    const alert = await this.alertCtrl.create({
      header: 'Get free stuff',
      subHeader: `Get a free month of Unthread when you refer a friend and they sign up for a subscription.`,
      inputs: [
        {
          name: 'code',
          value: link,
          placeholder: '',
        },
      ],
      buttons: [
        {
          text: 'Copy Link',
          handler: async () => {
            document.addEventListener('copy', (e: ClipboardEvent) => {
              e.clipboardData.setData('text/plain', link);
              e.preventDefault();
              document.removeEventListener('copy', null);
            });
            document.execCommand('copy');

            this.msgSrvc.show('✓ Copied link to clipboard');
          },
        },
        {
          text: 'Dismiss',
        },
      ],
    });
    alert.present();
  }

  openSlack() {
    this.analyticsService.trackEvent('Nav', 'Open Slack Inbox');

    const slackTeamUrl = this.authService.tenant.slackTeamUrl;
    const slackBotId = this.authService.tenant.slackBotId;
    const slackTeamId = this.authService.tenant.slackTeamId;
    let url = '';

    if (slackTeamUrl) {
      url += `${slackTeamUrl}app_redirect?`;

      if (slackBotId) {
        url += `channel=${slackBotId}`;

        if (slackTeamId) {
          url += `&team=${slackTeamId}`;
        }
      } else {
        url += `id=${environment.slack.appID}`;
      }
    } else {
      url += `slack://`;

      if (slackBotId) {
        url += `channel?id=${slackBotId}`;

        if (slackTeamId) {
          url += `&team=${slackTeamId}`;
        }
      } else {
        url += `app?id=${environment.slack.appID}`;
      }
    }

    url += '&tab=home';

    window.open(url);
  }

  async openNotifications() {
    const popover = await this.popoverCtrl.create({
      component: NotificationsComponent,
      componentProps: {
        notifications: this.notifications,
        unreadCount: this.unreadNotifications,
      },
      showBackdrop: false,
      event,
      cssClass: 'notifications-popover',
    });
    popover.present();
  }

  loginOrSignup() {
    if (this.router.routerState.snapshot.url.startsWith('/login')) {
      return;
    }

    this.navController.navigateRoot(['/login'], {
      queryParams: {
        redirect: this.router.routerState.snapshot.url,
      },
    });
  }

  handlePushAction(action: any) {
    const notif = action.notification;

    if (notif) {
      const planId = _.get(notif, 'data.planId');
      // const type = _.get(notif, 'data.alertType');
      // const guestId = _.get(notif, 'data.guestId');

      if (planId) {
        this.navController.navigateForward(`/events/${planId}`);
      }
    }
  }

  returnToLogin() {
    this.authService.logout();
  }

  async resendVerificationEmail() {
    await this.authService.sendVerificationEmailForUser(this.authService.user);
    this.msgSrvc.show('✓ Verification email sent');
  }

  async showCreateOptions() {
    const isCompanyAdmin = this.authService.isCompanyAdmin;

    const CREATE_CONVERSATION = 'Create conversation';
    const CONNECT_CHANNEL = 'Connect Slack channels';
    const CREATE_CUSTOMER = 'Create customer profile';
    const CREATE_OUTBOUND = 'Compose a Slack broadcast';

    if (!isCompanyAdmin) {
      this.globalCreateService.conversation();
      return;
    }

    const options = [
      {
        label: CREATE_CONVERSATION,
        icon: 'file-tray-outline',
        id: 'global-create-conversation-btn',
      },
      {
        label: CONNECT_CHANNEL,
        icon: 'logo-slack',
        id: 'global-connect-channel-btn',
      },
      {
        label: CREATE_CUSTOMER,
        icon: 'business-outline',
        id: 'global-create-customer-btn',
      },
      {
        label: CREATE_OUTBOUND,
        icon: 'send-outline',
        id: 'global-create-outbound-btn',
      },
    ];

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (_index: number, label: string) => {
          popover.dismiss();

          if (label === CREATE_CONVERSATION) {
            this.globalCreateService.conversation();
          } else if (label === CREATE_CUSTOMER) {
            this.globalCreateService.customer();
          } else if (label === CREATE_OUTBOUND) {
            this.globalCreateService.broadcast();
          } else if (label === CONNECT_CHANNEL) {
            this.globalCreateService.connectChannels();
          }
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  async showSuggestReloadPrompt() {
    const msg = await this.msgSrvc.show('A new version of Unthread is available', {
      duration: 0,
      position: 'bottom',
      buttons: [
        {
          text: 'Reload Now',
          handler: () => {
            window.location.reload();
          },
        },
        {
          text: 'Dismiss',
          role: 'cancel',
          handler: () => {
            msg.dismiss();
          },
        },
      ],
    });
  }
}
